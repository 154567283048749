<!-- src/components/WhatsAppForm.vue -->
<template>
  <v-container>
    <v-form @submit.prevent="sendMessage">
      <v-dialog v-model="showForm" max-width="1000px" persistent>
        <v-card>
          <v-card-text>
            <v-text-field
              v-model="phoneNumber"
              label="Phone Number"
              required
            ></v-text-field>
            <v-textarea v-model="message" label="Message" required></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="sendMessage" color="primary">Send Message</v-btn>
            <v-btn color="blue darken-1" @click="close"> Close </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-form>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
  </v-container>
</template>

<script>
import SEND_MESSAGE from "../graphql/SEND_MESSAGE.gql";
import axios from "axios";

export default {
  props: {
    showForm: Boolean,
  },
  data() {
    return {
      progress: false,

      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      phoneNumber: "+213550910239", // The phone number in international format
      message: "Hello, I want to know more about your services.",
      apiUrl: "https://graph.facebook.com/v19.0/377254465474459/messages", // Your WhatsApp API endpoint
      apiToken:
        "EAAOgsgkIcyEBO7ZAZBqpMtjpdSAYnaGqkl3hCaJjb36JPZCVopugTqGVij9pwaggDbLZBbjGrMXZCUWgXg4pgw69aftDQrvQQuzBUqN5qk9cXaCLV7cNhnTY8VSfd8CI7kKVPWq7rn9pKGgxNrz1XMyBC418Mwkt6vih3HCDZBz1IizWOIKEj3mmui0QozDxTG1tva1ZC5rHTgWa4E2I6C4YuSU4vCo", // Your API token
    };
  },

  methods: {
    async send_message() {
      let r;
      this.progress = true;
      await this.$apollo
        .mutate({
          mutation: SEND_MESSAGE,
          variables: {
            phone: this.phone,
            message: this.message,
          },
        })
        .then((data) => {
          r = data.data;
          console.log("Message sent successfully");
          this.progress = false;
        })
        .catch((error) => {
          console.log("Failed to send message");
          this.progress = false;
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
        });
      return r;
    },
    async sendMessage() {
      try {
        const response = await axios.post(
          this.apiUrl,
          {
            messaging_product: "whatsapp",
            recipient_type: "individual",
            to: this.phoneNumber,

            type: "template",
            template: { name: "hello_world", language: { code: "en_US" } },
            text: { body: "this.message", preview_url: true },
            location: {
              name: "Philz Coffee",
              latitude: 37.44216251868683,
              longitude: -122.16153582049394,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${this.apiToken}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log("Message sent:", response.data);
      } catch (error) {
        console.error("Error sending message:", error);
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
